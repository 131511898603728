import React from "react";

export interface NavIconProps {
  fill: string;
}

export const NavIcon: React.FC<NavIconProps> = ({
  fill
}: NavIconProps) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 124 124"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M112,6H12C5.4,6,0,11.4,0,18s5.4,12,12,12h100c6.6,0,12-5.4,12-12S118.6,6,112,6z" fill={fill} />
    <path d="M112,50H12C5.4,50,0,55.4,0,62c0,6.6,5.4,12,12,12h100c6.6,0,12-5.4,12-12C124,55.4,118.6,50,112,50z" fill={fill} />
	  <path d="M112,94H12c-6.6,0-12,5.4-12,12s5.4,12,12,12h100c6.6,0,12-5.4,12-12S118.6,94,112,94z" fill={fill} />
  </svg>
);
